








































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import apiClient from "@/services/apiClient";
import userManager from "@/services/userManager";
import util from "@/services/util";
import ImageInput from "@/views/app/policies/ImageInput.vue";
import moment from "moment";

export default Vue.extend({
  components: {
    ImageInput,
  },
  data() {
    return {
      util,
      userManager,
      dialog: false,
      dialogEdit: false,
      dialogCostName: false,
      dialogStandards: false,
      formCostName: false,
      formStandards: false,
      hideCreate: false,
      loadingCreate: false,
      snackbar: false,
      fails: "",
      name: "",
      form: false,
      profit: 0,
      collectCustomers: 0,
      co: false,
      dialogDelete: false,
      dialogApprove: false,
      dialogRejectApprove: false,
      ruleRequired: (value) => !!value || this.$t("REQUIRED"),
      ruleRequiredArray: (value) =>
        !!(Array.isArray(value) && value.length > 0) || this.$t("REQUIRED"),
      headers: [
        { text: "Mã đơn", value: "code" },
        { text: "Trạng thái đơn", value: "status" },
        { text: "Tên hàng", value: "product_name" },
        { text: "Tên chi phí", value: "cost_name" },
        { text: "Quy chuẩn", value: "standard" },
        { text: "Thu khách", value: "collect_customers" },
        { text: "Trạng thái thanh toán", value: "payment_status" },
        { text: "Hình ảnh", value: "images" },
        { text: "Đăng ký", value: "register" },
        { text: "Kết quả", value: "result" },
        { text: "Chi phí mềm", value: "soft_cost" },
        { text: "Chi phí khác", value: "other_cost" },
        { text: "Trạng thái thống kê", value: "statistical_status" },
        { text: "Lợi nhuận", value: "profit" },
        { text: "ghi chú", value: "note" },
        { text: "Ngày tạo đơn", value: "created_at" },
        { text: "Ngày thanh lý", value: "liquidation_date" },
        { text: "Trạng thái chi", value: "spending_status" },
        { text: "Hình ảnh duyệt chi", value: "spending_images" },
        { text: this.$t("ACTIONS"), value: "actions", sortable: false },
      ],
      options: {},
      search: {
        created_by: "",
        type: "",
        status: "",
        payment_status: "",
        statistical_status: "",
        created_at: "",
      },
      loading: false,
      data: [],
      dataCount: 0,
      editedIndex: -1,
      editedItem: {},
      currentImages: [],
      allowDelete: {},
      users: [],
      approves: [],
      types: [],
      costNames: [],
      standards: [],
      statusPayment: [
        {
          name: "Thanh toán trước",
          id: "1",
        },
        {
          name: "Theo thanh lý",
          id: "2",
        },
        {
          name: "Chưa đủ",
          id: "3",
        },
      ],
      listStatus: [
        {
          name: "Chưa có",
          id: "0",
        },
        {
          name: "Chưa thanh lý",
          id: "1",
        },
        {
          name: "Đã thanh lý",
          id: "2",
        },
      ],
      statisticalStatus: [
        {
          name: "Thiếu",
          id: "1",
        },
        {
          name: "Đủ",
          id: "2",
        },
      ],
      userInfo: userManager.getUserInfo(),
      urlFunc(item) {
        return Vue.filter("discussionMessageImageUrl")(item);
      },
      spendingUrlFunc(item) {
        return Vue.filter("policySpendingImageUrl")(item);
      },
      dialogApproveSpending: false,
      dialogApproveSpendingForm: false,
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Tạo chính sách" : this.$t("EDIT");
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogEdit(val) {
      val || this.closeEdit();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
    "search.created_at": {
      handler(val) {
        if (!val) {
          this.initialize();
        }
      },
      deep: true,
    },
    dialogCostName: {
      handler(val) {
        if (val === false) {
          this.hideCreate = false;
          this.name = "";
        }
      },
      deep: true,
    },
  },
  methods: {
    async initialize() {
      const filters = [];
      const search = { ...this.search };
      for (const searchKey in search) {
        if (search[searchKey]) {
          if (searchKey === "id") {
            search[searchKey] = search[searchKey].replace("ĐX", "");
          }
          if (searchKey === "created_at") {
            const m = moment(search[searchKey]);
            filters.push({
              key: searchKey,
              operator: ">=",
              value: m.startOf("month").format("Y-MM-DD"),
            });
            filters.push({
              key: searchKey,
              operator: "<=",
              value: m.endOf("month").format("Y-MM-DD"),
            });
          } else if (searchKey === "code") {
            filters.push({
              key: searchKey,
              operator: "LIKE",
              value: `%${search[searchKey]}%`,
            });
          } else if (searchKey === "approve") {
            filters.push({
              key: searchKey,
              operator: "in_json",
              value: [search[searchKey]],
            });
          } else {
            filters.push({
              key: searchKey,
              operator: "=",
              value: search[searchKey],
            });
          }
        }
      }
      this.loading = true;
      const { items, count } = await apiClient.policiesGetAll({
        options: this.options,
        filters,
      });
      this.data = items;
      this.dataCount = count;
      this.loading = false;
      await this.getCount();
    },
    async getCount() {
      this.profit = 0;
      this.collectCustomers = 0;
      const filters = [];
      const m = moment(this.search["created_at"]);

      filters.push({
        key: "statistical_status",
        operator: "=",
        value: "2",
      });
      if (this.search["created_at"]) {
        filters.push({
          key: "created_at",
          operator: ">=",
          value: m.startOf("month").format("Y-MM-DD"),
        });
        filters.push({
          key: "created_at",
          operator: "<=",
          value: m.endOf("month").format("Y-MM-DD"),
        });
      }
      const { items } = await apiClient.policiesGetAll({
        options: {},
        filters,
      });
      if (items && Array.isArray(items) && items.length) {
        items.forEach((v) => {
          this.profit += v.profit;
          this.collectCustomers += v.collect_customers;
        });
      }
    },
    editItem(item) {
      if (item.images && Array.isArray(item.images)) {
        this.currentImages = item.images;
      }
      this.editedIndex = this.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      if (this.userInfo.roles.includes("cs")) {
        this.dialogEdit = true;
      } else {
        this.dialog = true;
      }
    },
    deleteItem(item) {
      this.editedIndex = this.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    approveItem(item) {
      this.editedIndex = this.data.indexOf(item);
      this.editedItem.id = item.id;
      this.dialogApprove = true;
    },
    rejectItem(item) {
      this.editedIndex = this.data.indexOf(item);
      this.editedItem.id = item.id;
      this.dialogRejectApprove = true;
    },
    async deleteItemConfirm() {
      this.closeDelete();
      await apiClient.policiesDelete(this.editedItem);
      await this.initialize();
    },
    async approveItemConfirm() {
      this.closeApprove();
      const params = {
        status: "1",
        directive: this.editedItem.directive,
        approved_person: String(this.userInfo.sub),
        id: this.editedItem.id,
      };
      await apiClient.suggestionsApprove(params);
      await this.initialize();
    },
    async rejectApproveItemConfirm() {
      this.closeRejectApprove();
      const params = {
        status: "2",
        directive: this.editedItem.directive,
        approved_person: String(this.userInfo.sub),
        id: this.editedItem.id,
      };
      await apiClient.suggestionsApprove(params);
      await this.initialize();
    },
    approveSpendingItem(item) {
      this.editedIndex = this.data.indexOf(item);
      this.editedItem.id = item.id;
      this.dialogApproveSpending = true;
    },
    async approveSpendingItemConfirm() {
      this.closeApproveSpending();
      const params = {
        spending_status: "1",
        id: this.editedItem.id,
        images: this.editedItem.spending_images,
      };
      await apiClient.policiesApproveSpending(params);
      await this.initialize();
    },
    closeApproveSpending() {
      this.dialogApproveSpending = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeEdit() {
      this.dialogEdit = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeApprove() {
      this.dialogApprove = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeRejectApprove() {
      this.dialogRejectApprove = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async save() {
      let result;
      const userInfo = { ...this.userManager.getUserInfo() };
      if (
        this.currentImages &&
        Array.isArray(this.currentImages) &&
        this.currentImages.length
      ) {
        this.editedItem.images = this.currentImages;
      }
      const allowConvertNumber = [
        "collect_customers",
        "register",
        "result",
        "soft_cost",
        "other_cost",
        "profit",
      ];
      if (this.editedItem) {
        for (const editKey in this.editedItem) {
          if (allowConvertNumber.includes(editKey)) {
            this.editedItem[editKey] = Number(this.editedItem[editKey]);
          }
        }
      }
      if (this.editedIndex > -1) {
        result = await apiClient.policiesUpdate(this.editedItem);
      } else {
        if (userInfo.sub) {
          this.editedItem["created_by"] = userInfo.sub;
        }
        result = await apiClient.policiesCreate(this.editedItem);
      }
      if (!result.errors) {
        this.closeEdit();
        this.close();
        await this.initialize();
      } else {
        this.snackbar = true;
        this.fails = result.message;
      }
    },
    getAllUser() {
      this.usersByRoles = [];
      apiClient
        .userGetAll()
        .then((res) => {
          this.users = [
            {
              id: "all",
              name: "Tất cả",
            },
          ].concat(
            res.items.map((item) => {
              item.id = String(item.id);
              return item;
            })
          );

          this.approves = [...this.users];
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {});
    },
    getName(value, data, keyData = "id", keyName = "name") {
      if (value) {
        if (Array.isArray(data)) {
          const length = data.length;
          for (let i = 0; i < length; i++) {
            if (data[i][keyData] === value) {
              return data[i][keyName];
            }
          }
        }
        return value;
      }
      return "";
    },
    goToDetailsObject(record) {
      console.log(record);
    },
    onChangeObject(val) {
      const isAll = val.includes("all");
      if (isAll) {
        this.editedItem.object = this.users.map((item) => {
          return item.id;
        });
        this.users.shift();
        this.users.unshift({
          name: "Bỏ chọn",
          id: "removeAll",
        });
      }
      if (val.includes("removeAll")) {
        this.editedItem.object = [];
        this.users.shift();
        this.users.unshift({
          name: "Tất cả",
          id: "all",
        });
      }
    },
    onChangeApprove(val) {
      const isAll = val.includes("all");
      if (isAll) {
        this.editedItem.approve = this.approves.map((item) => {
          return item.id;
        });
        this.users.shift();
        this.users.unshift({
          name: "Bỏ chọn",
          id: "removeAll",
        });
      }
      if (val.includes("removeAll")) {
        this.editedItem.approve = [];
        this.users.shift();
        this.users.unshift({
          name: "Tất cả",
          id: "all",
        });
      }
    },
    async costNameDelete(id) {
      this.loadingCreate = true;
      const x = await apiClient.costNameDelete(id);
      if (x) {
        this.getCostName();
        this.loadingCreate = false;
      }
    },
    async standardsDelete(id) {
      this.loadingCreate = true;
      const x = await apiClient.standardsDelete(id);
      if (x) {
        this.getStandards();
        this.loadingCreate = false;
      }
    },
    getCostName() {
      const filters = [];
      apiClient
        .costNameGetAll({
          options: {},
          filters,
        })
        .then((res) => {
          this.costNames = res.items.map((item) => {
            return {
              name: item.name,
              id: String(item.id),
            };
          });
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {});
    },
    getStandards() {
      const filters = [];
      apiClient
        .standardsGetAll({
          options: {},
          filters,
        })
        .then((res) => {
          this.standards = res.items.map((item) => {
            return {
              name: item.name,
              id: String(item.id),
            };
          });
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {});
    },
    async saveCode() {
      this.hideCreate = !this.hideCreate;
      this.loadingCreate = true;
      const params = {
        name: this.name,
      };
      const res = await apiClient.costNameCreate(params);
      if (res) {
        this.getCostName();
        this.name = "";
        this.loadingCreate = false;
      }
    },
    async saveStandards() {
      this.hideCreate = !this.hideCreate;
      this.loadingCreate = true;
      const params = {
        name: this.name,
      };
      const res = await apiClient.standardsCreate(params);
      if (res) {
        this.getStandards();
        this.name = "";
        this.loadingCreate = false;
      }
    },
    closeCostName() {
      this.dialogCostName = false;
    },
    closeStandards() {
      this.dialogStandards = false;
    },
    checkRoles() {
      const roles = userManager.getUserInfo().roles;
      const allCanSee = [
        "code",
        "status",
        "product_name",
        "cost_name",
        "standard",
        "note",
        "actions",
      ];
      const rolesKd = [
        "collect_customers",
        "payment_status",
        "images",
        "created_at",
        "liquidation_date",
      ].concat(allCanSee);
      const rolesCs = [
        "register",
        "result",
        "soft_cost",
        "other_cost",
        "statistical_status",
      ].concat(allCanSee);
      if (roles.includes("kd")) {
        this.headers = this.headers.filter((item) => {
          return rolesKd.includes(item.value);
        });
      }
      if (roles.includes("cs")) {
        this.headers = this.headers.filter((item) => {
          return rolesCs.includes(item.value);
        });
      }
    },
  },
  created() {
    this.checkRoles();
    this.getAllUser();
    this.getCostName();
    this.getStandards();
  },
});
